import React from 'react';
import style from './mySkills.scss';
import Header from '../header/Header';
import SkillList from '../skillsList/SkillsList';
import ToolsList from '../toolsList/ToolsList';

class MySkills extends React.PureComponent {
  render() {
    const title =
      'Travailler et échanger avec mes clients enrichit mes connaissances digitales';
    const tags = ['Backend', 'Frontend', 'Base de données', 'Outils'];
    return (
      <div>
        <Header title={title} tags={tags} />
        <div className={style.container}>
          <SkillList type="frontend" />
          <SkillList type="backend" />
        </div>
        <div className={style['tools--container']}>
          <SkillList type="database" />
          <div className={style['tools--list']}>
            <ToolsList type="hosting" />
            <ToolsList type="source" />
          </div>
        </div>
      </div>
    );
  }
}

export default MySkills;
