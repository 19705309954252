import React from 'react';
import { Flip, Fade } from 'react-awesome-reveal';
import Header from '../header/Header';
import data from '../../data/customers.json';
import style from './myCustomers.scss';

class MyCustomers extends React.PureComponent {
  render() {
    const title = 'Ils m’ont fait confiance';
    const tags = [
      'aéronotique',
      'transports',
      'services',
      'énergies',
      'santé',
      'défense',
    ];
    const images = require.context(
      '../../assets/img/customers',
      true,
    );
    return (
      <div className={style.container}>
        <Header title={title} tags={tags} />
        <div className={style['text--container']}>
          <Fade direction="top" delay={500}>
            <p className={style['text--content']}>
              Nos marques préférées sont nos amis ! Je les aide à
              atteindre leurs objectifs, Elles m’aident à me
              surpasser.
            </p>
          </Fade>
        </div>
        <div className={style.customers}>
          {data.map((item) => {
            return (
              <Flip
                key={item.id}
                direction="horizontal"
                delay={150 * item.id}
                className={style['img--container']}
              >
                <img
                  src={images(`./${item.img}`).default}
                  className={style.logo}
                  alt="client"
                  key={item.id}
                />
              </Flip>
            );
          })}
        </div>
      </div>
    );
  }
}

export default MyCustomers;
