import React from 'react';
import style from './hero.scss';
import hero from '../../assets/img/thumb.jpg';

class Hero extends React.PureComponent {
  render() {
    return (
      <div className={style.container}>
        <div className={style.row}>
          <div className={style['hero--pic']}>
            <img
              className={style['hero--pic-img']}
              src={hero}
              alt="thumb"
            />
          </div>
          <div className={style['hero--infos']}>
            <div>
              <p>Alexis TOURNELLE</p>
            </div>
            <div>
              <p>Développeur Fullstack & Fondateur d’AltoDev</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Hero;
