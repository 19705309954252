import React from 'react';
// import Slide from 'react-reveal/Slide';
import { Fade, Slide } from 'react-awesome-reveal';
import style from './presentation.scss';
import Hero from '../hero/Hero';

class Presentation extends React.PureComponent {
  render() {
    return (
      <div className={style.container}>
        <Fade direction="top">
          <div className={style['presentation--title']}>
            <h1>Je suis développeur Fullstack</h1>
          </div>
        </Fade>
        <div className={style.presentation}>
          <div className={style['presentation--img']} />
          <Slide
            direction="right"
            cascade
            delay={1000}
            className={style['presentation--content']}
          >
            <div className={style['presentation--content-text']}>
              <p>
                Je m’appelle Alexis TOURNELLE, développeur fullstack
                et fondateur d’AltoDev.
              </p>
              <p>
                Après 8 ans d’expérience au service de grands groupes
                (Airbus, Engie, Ministère de la défense, Aéroports de
                paris...) en tant que consultant, j’ai décidé de
                mettre mon expérience du monde digital et mon savoir
                faire à votre service.
              </p>
            </div>
            <div className={style.hero}>
              <Hero />
            </div>
          </Slide>
        </div>
      </div>
    );
  }
}

export default Presentation;
