import React from 'react';
import style from './cardList.scss';
import Card from '../card/Card';

import data from '../../data/services.json';

class CardList extends React.PureComponent {
  render() {
    return (
      <div className={style.container}>
        {data.map((item, index) => {
          return (
            <div className={style['card--item']} key={item.id}>
              <Card
                text={item.title}
                img={item.img}
                details={item.details}
                index={index}
              />
            </div>
          );
        })}
      </div>
    );
  }
}

export default CardList;
