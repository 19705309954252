import React from 'react';
import { Fade } from 'react-awesome-reveal';
import style from './testimonials.scss';
import data from '../../data/testimonials.json';

class Testimonials extends React.PureComponent {
  render() {
    const images = require.context(
      '../../assets/img/testimonials',
      true,
    );
    return (
      <div className={style.container}>
        {data.map((item) => {
          return (
            <Fade
              direction="top"
              className={style.testimonial}
              key={item.id}
            >
              <div className={style['testimonial--content']}>
                <p className={style['testimonial--content-text']}>
                  {`"${item.text}"`}
                </p>
                <div>
                  <Fade direction="top" delay={500}>
                    <p>
                      <b>{item.name}</b>
                    </p>
                    <p>{item.job}</p>
                  </Fade>
                </div>
              </div>
              <div className={style['testimonial--img']}>
                <img
                  src={images(`./${item.img}`).default}
                  alt={item.name}
                  key={item.id}
                />
              </div>
            </Fade>
          );
        })}
      </div>
    );
  }
}

export default Testimonials;
