/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import style from './navbar.scss';
import Logo from '../../assets/img/logos/altoDev.png';

class NavBar extends React.PureComponent {
  constructor(props) {
    super(props);

    this.navLinksRef = React.createRef();
    this.burgerRef = React.createRef();

    this.showNav = this.showNav.bind(this);
  }

  // toogle navigation
  showNav() {
    // toogle nav
    this.navLinksRef.current.classList.toggle(style['nav-active']);

    // animate links
    const navLinks = this.navLinksRef.current.querySelectorAll('li');

    navLinks.forEach((link, index) => {
      if (link.style.animation) {
        link.style.animation = '';
      } else {
        const delay = index / 7 + 0.4;
        link.style.animation = `${style.navLinkFade} 0.5s ease forwards ${delay}s`;
      }
    });

    // animate burger
    this.burgerRef.current.classList.toggle(style.toggle);

    if (this.props.sticky) {
      this.navLinksRef.current.style.backgroundColor = '#243a45';
    }
  }

  render() {
    return (
      <nav
        className={this.props.sticky ? style['navbar-sticky'] : ''}
      >
        <div className={style['navbar--logo-holder']}>
          {this.props.sticky ? (
            <img
              src={Logo}
              alt="logo"
              className={style['navbar--logo']}
            />
          ) : null}
          <p>{'{{ AltoDev }}'}</p>
        </div>
        <ul className={style['navbar--links']} ref={this.navLinksRef}>
          <li>
            <a href="#apropos" onClick={this.showNav}>
              A Propos
            </a>
          </li>
          <li>
            <a href="#services" onClick={this.showNav}>
              Services
            </a>
          </li>
          <li>
            <a href="#skills" onClick={this.showNav}>
              Skills
            </a>
          </li>
          <li>
            <a href="#realisations" onClick={this.showNav}>
              Réalisations
            </a>
          </li>
          <li className={style['navbar--links-accent']}>
            <a href="#contact" onClick={this.showNav}>
              Contact
            </a>
          </li>
        </ul>
        <div
          className={style.burger}
          onClick={this.showNav}
          ref={this.burgerRef}
        >
          <div className={style.line1} />
          <div className={style.line2} />
          <div className={style.line3} />
        </div>
      </nav>
    );
  }
}
export default NavBar;
