import React from 'react';
import PropTypes from 'prop-types';
import data from '../../data/skills.json';
import style from './skillsList.scss';
import Skill from '../skill/Skill';

class SkillList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      skills: [],
      label: '',
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const { type } = this.props;
    const dataset = data.filter((d) => d.code === type);

    if (dataset) {
      const { skills } = dataset[0];

      this.setState({
        skills,
        label: dataset[0].label,
      });
    }
  }

  render() {
    const { label, skills } = this.state;
    return (
      <div className={style.container}>
        <div className={style.title}>
          <h3>{label}</h3>
        </div>

        {skills.map((item) => {
          return (
            <div key={item.id}>
              <Skill
                label={item.label}
                img={item.img}
                rate={item.rate}
                color={item.color}
              />
            </div>
          );
        })}
      </div>
    );
  }
}

SkillList.propTypes = {
  type: PropTypes.string,
};

SkillList.defaultProps = {
  type: 'skill',
};

export default SkillList;
