import React from 'react';
import PropTypes from 'prop-types';
import style from './separator.scss';

class Separator extends React.PureComponent {
  render() {
    const { title, isOnLeft } = this.props;
    return (
      <div className={style.container}>
        <div
          className={
            isOnLeft === true
              ? style['title--left']
              : style['title--right']
          }
        >
          <p className={style['title--text']}>{`- ${title}`}</p>
        </div>
      </div>
    );
  }
}

Separator.propTypes = {
  title: PropTypes.string,
  isOnLeft: PropTypes.bool,
};

Separator.defaultProps = {
  title: 'Titre',
  isOnLeft: true,
};

export default Separator;
