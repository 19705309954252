/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
/* eslint-disable class-methods-use-this */
import React from 'react';
import { Zoom } from 'react-awesome-reveal';
import PropTypes from 'prop-types';
import style from './card.scss';

import DevImg from '../../assets/img/cards/coding.jpg';
import DbImg from '../../assets/img/cards/db.jpg';
import HostingImg from '../../assets/img/cards/hosting.jpg';
import SeoImg from '../../assets/img/cards/seo.jpg';
import AnalysisImg from '../../assets/img/cards/analysis.jpg';
import MaintenanceImg from '../../assets/img/cards/maintenance.jpg';

class Card extends React.PureComponent {
  constructor(props) {
    super(props);

    this.cardDetailsref = React.createRef();

    this.cardImage = this.cardImage.bind(this);
    this.showDetails = this.showDetails.bind(this);
    this.hideDetails = this.hideDetails.bind(this);
  }

  // select card image
  cardImage(imgName) {
    switch (imgName) {
      case 'coding.jpg':
        return DevImg;
      case 'db.jpg':
        return DbImg;
      case 'hosting.jpg':
        return HostingImg;
      case 'seo.jpg':
        return SeoImg;
      case 'analysis.jpg':
        return AnalysisImg;
      case 'maintenance.jpg':
        return MaintenanceImg;
      default:
        return '';
    }
  }

  showDetails() {
    const parentHeight = this.cardDetailsref.current.parentElement
      .clientHeight;

    const parentWidth = this.cardDetailsref.current.parentElement
      .clientWidth;

    this.cardDetailsref.current.classList.toggle(
      style['card--active'],
    );
    this.cardDetailsref.current.style.height = `${parentHeight}px`;
    this.cardDetailsref.current.style.width = `${
      parentWidth / 1.5
    }px`;
  }

  hideDetails() {
    this.cardDetailsref.current.classList.toggle(
      style['card--active'],
    );
  }

  render() {
    const { img, text, details, index } = this.props;
    return (
      <Zoom direction="right" delay={index * 150} duration={800}>
        <div className={style.card}>
          <div
            className={style['card--content']}
            onMouseEnter={this.showDetails}
            onMouseLeave={this.hideDetails}
          >
            <div
              className={style['card--details']}
              ref={this.cardDetailsref}
            >
              {details.map((item) => {
                return <p key={item}>{`- ${item}`}</p>;
              })}
            </div>
            <div className={style['card--pic']}>
              <img
                className={style['card--pic-img']}
                src={this.cardImage(img)}
                alt="service"
              />
            </div>
            <div className={style['card--text']}>
              <p>{text}</p>
            </div>
          </div>
        </div>
      </Zoom>
    );
  }
}

Card.propTypes = {
  img: PropTypes.string,
  text: PropTypes.string,
  details: PropTypes.array,
  index: PropTypes.number,
};

Card.defaultProps = {
  img: 'Img',
  text: 'service',
  details: [],
  index: 0,
};

export default Card;
