import React from 'react';
import { Fade } from 'react-awesome-reveal';
import style from './myProjects.scss';
import Header from '../header/Header';
import data from '../../data/projects.json';

class MyProjects extends React.PureComponent {
  render() {
    const images = require.context('../../assets/img/projects', true);
    const title =
      'Découvrez les projets sur lesquels je suis intervenu';
    const tags = ['Aéronautique', 'Web', 'Social', 'Digital'];
    return (
      <div className={style.container}>
        <Header title={title} tags={tags} />
        <div className={style.projects}>
          {data.map((item) => {
            return (
              <Fade
                direction="top"
                className={style['card--project']}
                key={item.id}
              >
                <img
                  src={images(`./${item.img}`).default}
                  className={style.img}
                  alt="project"
                />
                <div>
                  <a href={item.url} target="_blank" rel="noreferrer">
                    {`${item.label} -->`}
                  </a>
                </div>
              </Fade>
            );
          })}
        </div>
        <div>
          <Fade direction="top">
            <p>
              Ce sont mes projets les plus récents, je ne peux
              afficher mes projets plus anciens car ils étaient des
              outils internes à l’ecosytème digital de clients grands
              comptes ou bien classés confidentiels.
            </p>
          </Fade>
        </div>
      </div>
    );
  }
}

export default MyProjects;
