import React from 'react';
import style from './footer.scss';

class Footer extends React.PureComponent {
  render() {
    return (
      <footer className={style.footer}>
        <ul className={style['footer--links']}>
          <li>
            <a
              href="https://viadeo.journaldunet.com/p/alexis-tournelle-1793474"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                className={style['footer--pictos']}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 96.55 96.55"
                xmlSpace="preserve"
              >
                <g>
                  <g>
                    <path
                      d="M72.496,48.53c-0.063-0.159-0.149-0.308-0.227-0.465c-0.884,0.457-1.718,0.926-2.586,1.324
			c-0.844,0.389-1.711,0.737-2.589,1.043c-0.894,0.311-1.814,0.549-2.716,0.815c0.007,0.082,0.002,0.133,0.016,0.18
			c0.037,0.12,0.077,0.24,0.124,0.356c1.26,3.179,1.816,6.492,1.857,9.896c0.034,2.838-0.333,5.631-1.166,8.35
			c-1.253,4.088-3.274,7.75-6.253,10.83c-4.353,4.496-9.706,6.916-15.917,7.48c-0.574,0.053-1.152,0.046-1.777,0.068
			c0.028-0.141,0.024-0.172,0.038-0.182c0.322-0.242,0.647-0.479,0.968-0.723c6.493-4.93,11.606-11.01,15.22-18.322
			c3.005-6.078,4.532-12.525,4.684-19.302c0.073-3.312-0.217-6.597-0.79-9.854c-0.65-3.705-1.671-7.313-2.985-10.837
			c-0.041-0.109-0.165-0.374-0.252-0.531c-0.079-0.583-0.104-0.759-0.122-0.868c0-0.101-0.006-0.284-0.01-0.322
			c-0.324-3.061-0.879-6.077-1.72-9.038c-1.143-4.021-2.764-7.83-5.035-11.348c-1.565-2.424-3.37-4.647-5.57-6.531
			C45.481,0.373,45.262,0.207,45.002,0c-0.008,0.137-0.039,0.219-0.011,0.271c0.155,0.288,0.324,0.568,0.488,0.852
			c3.824,6.584,7.182,13.399,10.051,20.452c0.788,1.934,1.507,3.896,2.259,5.845l0.222,0.408c0.019,0.115,0.046,0.316,0.121,0.867
			c0.04,1.028,0.096,2.084,0.115,2.996c0.109,5.346-0.032,10.682-0.578,16.005c-0.251,2.458-0.564,4.906-0.98,7.337
			c-0.521,3.056-1.19,6.08-2.062,9.056c-1.073,3.659-2.42,7.208-4.24,10.567c-1.619,2.986-3.562,5.734-6.01,8.104
			c-2.251,2.18-4.808,3.896-7.705,5.1c-0.309,0.129-0.602,0.166-0.946,0.099c-3.955-0.772-7.515-2.401-10.682-4.888
			c-2.737-2.148-4.859-4.812-6.515-7.859c-1.767-3.252-2.825-6.725-3.191-10.402c-0.215-2.164-0.168-4.326,0.091-6.488
			c0.318-2.646,0.988-5.191,2.071-7.625c1.613-3.627,3.973-6.705,6.918-9.346c2.273-2.038,4.813-3.645,7.676-4.728
			c1.847-0.698,3.752-1.139,5.713-1.371c1.735-0.204,3.474-0.213,5.208-0.077c2.218,0.174,4.38,0.654,6.461,1.463
			c0.18,0.07,0.37,0.115,0.635,0.197c0.183-1.451,0.468-2.825,0.896-4.167c0.429-1.342,1.006-2.626,1.603-3.903
			c-0.326-0.257-0.681-0.314-1.01-0.42c-2.82-0.907-5.713-1.401-8.671-1.568c-3.163-0.18-6.294,0.033-9.398,0.65
			c-3.718,0.739-7.189,2.11-10.404,4.122c-3.06,1.916-5.703,4.307-8.038,7.05c-5.18,6.085-7.943,13.133-8.369,21.1
			c-0.122,2.291-0.045,4.582,0.245,6.865c0.408,3.221,1.223,6.326,2.512,9.305c2.083,4.814,5.122,8.949,8.973,12.49
			c2.85,2.621,6.096,4.621,9.713,6.002c3.37,1.289,6.875,1.959,10.467,2.136c3.333,0.163,6.646-0.024,9.924-0.714
			c4.427-0.93,8.477-2.698,12.117-5.393c2.84-2.103,5.248-4.631,7.351-7.457c4.177-5.613,6.439-11.928,6.836-18.906
			c0.162-2.852,0.027-5.693-0.441-8.515C74.013,53.117,73.4,50.783,72.496,48.53z"
                    />
                    <path
                      d="M88.79,10.815c-0.731-2.712-1.759-5.303-3.167-7.737c-0.201-0.346-0.424-0.68-0.681-1.09
			c-0.193,0.352-0.353,0.63-0.502,0.913c-0.973,1.84-2.187,3.503-3.685,4.951c-2.272,2.193-4.892,3.782-7.98,4.539
			c-0.269,0.066-0.537,0.139-0.803,0.217c-2.277,0.667-4.479,1.516-6.54,2.711c-1.412,0.82-2.624,1.859-3.624,3.155
			c-1.86,2.409-2.759,5.127-2.625,8.167c0.11,2.483,0.82,4.804,2.02,6.977c0.059,0.106,0.144,0.197,0.265,0.359
			c9.77-2.395,17.11-7.984,22.184-16.825c-0.019,0.298-0.01,0.442-0.04,0.578c-0.169,0.766-0.438,1.497-0.783,2.201
			c-0.762,1.551-1.787,2.919-2.931,4.203c-1.984,2.23-4.24,4.164-6.609,5.969c-2.869,2.186-5.876,4.166-8.995,5.975
			c-0.229,0.133-0.502,0.219-0.675,0.509c0.423,0.379,0.824,0.759,1.247,1.114c1.597,1.339,3.34,2.424,5.331,3.081
			c1.877,0.618,3.787,0.787,5.739,0.446c2.37-0.412,4.433-1.46,6.243-3.023c1.756-1.514,3.138-3.336,4.303-5.327
			c0.675-1.152,1.303-2.333,1.737-3.601c0.905-2.636,1.434-5.353,1.604-8.129C90.039,17.652,89.702,14.202,88.79,10.815z"
                    />
                  </g>
                </g>
              </svg>
            </a>
          </li>
          <li>
            <a
              href="https://fr.linkedin.com/in/alexis-tournelle-b4266340"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                className={style['footer--pictos']}
                viewBox="-21 -35 682.66669 682"
                xmlns="http://www.w3.org/2000/svg"
                xmlSpace="preserve"
              >
                <path d="m77.613281-.667969c-46.929687 0-77.613281 30.816407-77.613281 71.320313 0 39.609375 29.769531 71.304687 75.8125 71.304687h.890625c47.847656 0 77.625-31.695312 77.625-71.304687-.894531-40.503906-29.777344-71.320313-76.714844-71.320313zm0 0" />
                <path d="m8.109375 198.3125h137.195313v412.757812h-137.195313zm0 0" />
                <path d="m482.054688 188.625c-74.011719 0-123.640626 69.546875-123.640626 69.546875v-59.859375h-137.199218v412.757812h137.191406v-230.5c0-12.339843.894531-24.660156 4.519531-33.484374 9.917969-24.640626 32.488281-50.167969 70.390625-50.167969 49.644532 0 69.5 37.851562 69.5 93.339843v220.8125h137.183594v-236.667968c0-126.78125-67.6875-185.777344-157.945312-185.777344zm0 0" />
              </svg>
            </a>
          </li>
          <li>
            <a
              href="https://www.doyoubuzz.com/alexis-tournelle"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                className={style['footer--pictos']}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                xmlSpace="preserve"
              >
                <g>
                  <g>
                    <path
                      d="M333.205,34.213L330.799,0L9.546,22.591l30.317,431.118l44.011-3.095V512h322.046v-35.276l50.265,5.413l46.27-429.698
			L333.205,34.213z M83.874,420.519l-16.17,1.137L41.599,50.432l261.359-18.38l3.359,47.765H83.874V420.519z M375.9,481.979H113.895
			V109.838H375.9V481.979z M429.552,449.073l-23.631-2.545V79.817h-69.508l-1.068-15.18l134.049,14.434L429.552,449.073z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect
                      x="147.003"
                      y="296.746"
                      width="195.796"
                      height="30.021"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect
                      x="147.003"
                      y="350.143"
                      width="195.796"
                      height="30.021"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect
                      x="147.003"
                      y="403.54"
                      width="195.796"
                      height="30.021"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M201.384,229.293c-13.238,0-22.154-9.186-22.154-23.64s8.915-23.64,22.154-23.64c8.511,0,15.13,4.053,20.668,11.078
			l20.128-17.966c-9.321-12.157-23.911-18.912-42.418-18.912c-30.394,0-52.684,20.398-52.684,49.442s22.289,49.442,52.684,49.44
			c18.508,0,33.097-6.754,42.418-18.912l-20.128-17.966C216.514,225.24,209.895,229.293,201.384,229.293z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon
                      points="321.075,158.373 297.975,213.624 275.415,158.373 241.102,158.373 281.088,252.934 312.429,252.934 
			352.414,158.373 		"
                    />
                  </g>
                </g>
              </svg>
            </a>
          </li>
        </ul>
        <div className={style['footer--line']} />
        <div className={style['footer--copyright']}>
          <p>{`© ${new Date().getFullYear()} AltoDev Copyright`}</p>
        </div>
      </footer>
    );
  }
}

export default Footer;
