import React from 'react';
import Header from '../header/Header';

import CardList from '../cardList/CardList';

class MyServices extends React.PureComponent {
  render() {
    const title =
      'Je suis à l’écoute de vos besoins pour concrétiser vos projets';
    const tags = [
      'Développement',
      'SEO / Référencement',
      'Data',
      'Analyse de performances',
      'Hébergement / Cloud',
      'Maintenance',
    ];
    return (
      <div>
        <Header title={title} tags={tags} />
        <CardList />
      </div>
    );
  }
}

export default MyServices;
