import React from 'react';
import style from './welcome.scss';

const Welcome = () => {
  return (
    <main>
      <section className={style.container}>
        <div className={style['title--container']}>
          <div>
            <p className={style['title--text']}>
              Concrétisons ensemble&nbsp;
            </p>
          </div>
          <div className={style['title--text-bottom']}>
            <div>
              <p
                className={`${style['title--text']} ${style['title--text-accent']}`}
              >
                vos&nbsp;
              </p>
            </div>
            <div>
              <div className={style.cubespinner}>
                <div className={style.face1}>idées</div>
                <div className={style.face2}>projets</div>
                <div className={style.face3}>envies</div>
                <div className={style.face4}>concepts</div>
              </div>
            </div>
          </div>
        </div>
        <div className={style['subtitle--container']}>
          <span>{'{{ AltoDev }}'}</span>
        </div>
      </section>
    </main>
  );
};

export default Welcome;
