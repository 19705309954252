/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Fade } from 'react-awesome-reveal';
import PropTypes from 'prop-types';
import style from './header.scss';

class Header extends React.PureComponent {
  render() {
    const { title, tags } = this.props;
    return (
      <Fade direction="top">
        <div className={style.header}>
          <div className={style['header--title']}>
            <h1>{title}</h1>
          </div>
          <div className={style['header--tags']}>
            {tags.map((item) => {
              return <p key={item}>{item}</p>;
            })}
          </div>
        </div>
      </Fade>
    );
  }
}

Header.propTypes = {
  title: PropTypes.string,
  tags: PropTypes.array,
};

Header.defaultProps = {
  title: 'Img',
  tags: [],
};

export default Header;
