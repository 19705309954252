/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
import React from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import PropTypes from 'prop-types';
import style from './skill.scss';

class Skill extends React.PureComponent {
  componentDidMount() {
    const stylesheet = document.styleSheets[0];

    stylesheet.insertRule(
      `.show${this.props.rate} { width: ${this.props.rate}%;}`,
      stylesheet.rules.length,
    );
  }

  render() {
    const { img, color, rate, label } = this.props;
    const images = require.context('../../assets/img/skills', true);
    const imgsrc = images(`./${img}`).default;
    const divId = `skillContainer${
      Math.floor(Math.random() * 1000) + 1
    }`;
    return (
      <div id={divId} className={style.container}>
        <div className={style['skill--logo']}>
          <img src={imgsrc} className={style.logo} alt="skill" />
          <p>{label}</p>
        </div>
        <div className={style['skill--ratebar']}>
          <Controller>
            <Scene
              duration={1000}
              classToggle={`show${rate}`}
              triggerElement={`#${divId}`}
              offset={-170}
              // indicators
            >
              <div
                className={style['skill--rate']}
                style={{ backgroundColor: color }}
              >
                &nbsp;
              </div>
            </Scene>
          </Controller>
        </div>
      </div>
    );
  }
}

Skill.propTypes = {
  img: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string,
  rate: PropTypes.number,
};

Skill.defaultProps = {
  img: 'Img',
  label: 'Skill',
  color: '#ffffff',
  rate: 0,
};

export default Skill;
