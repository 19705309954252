import React from 'react';
import { Zoom } from 'react-awesome-reveal';
import PropTypes from 'prop-types';
import data from '../../data/tools.json';
import style from './toolsList.scss';

class ToolsList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tools: [],
      label: '',
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const { type } = this.props;
    const dataset = data.filter((d) => d.code === type);

    if (dataset) {
      const { tools } = dataset[0];

      this.setState({
        tools,
        label: dataset[0].label,
      });
    }
  }

  render() {
    const { label, tools } = this.state;
    const images = require.context('../../assets/img/tools', true);
    return (
      <div className={style.container}>
        <div className={style.title}>
          <h3>{label}</h3>
        </div>
        <div className={style.tools}>
          {tools.map((item) => {
            return (
              <Zoom
                key={item.id}
                delay={150 * item.id}
                className={style['img--container']}
              >
                <img
                  src={images(`./${item.img}`).default}
                  alt="skill"
                  key={item.id}
                />
              </Zoom>
            );
          })}
        </div>
      </div>
    );
  }
}

ToolsList.propTypes = {
  type: PropTypes.string,
};

ToolsList.defaultProps = {
  type: 'tool',
};

export default ToolsList;
