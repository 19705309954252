/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import ReactFormValidation from 'react-form-input-validation';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import style from './contact.scss';
import video from '../../assets/video/coffee.mp4';
import Header from '../header/Header';

class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        sender_name: '',
        sender_mail: '',
        message: '',
      },
      errors: {},
      isMailSent: false,
    };

    this.form = new ReactFormValidation(this, { locale: 'fr' });
    this.form.useRules({
      sender_name: 'required|max:30|min:4',
      sender_mail: 'required|email',
      message: 'required|max:800',
    });

    this.form.onformsubmit = (fields) => {
      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICEID,
          process.env.REACT_APP_EMAILJS_TEMPLATEID,
          fields,
          process.env.REACT_APP_EMAILJS_USERID,
        )
        .then(
          (result) => {
            this.setState({ isMailSent: true });
            Swal.fire({
              width: '25rem',
              showConfirmButton: true,
              timer: '2000',
              title: 'Message envoyé!',
              text: 'Je vous répondrai dès que possible',
              icon: 'success',
              customClass: {
                title: style['modal--title'],
                content: style['modal--content'],
                confirmButton: style['modal--button'],
              },
            });
          },
          (error) => {
            Swal.fire({
              width: '25rem',
              showConfirmButton: true,
              timer: '2500',
              title: 'Message non envoyé!',
              text: 'Veuillez réessayer ulterieurement',
              icon: 'error',
              customClass: {
                title: style['modal--title'],
                content: style['modal--content'],
                confirmButton: style['modal--button'],
              },
            });
          },
        );
    };
  }

  render() {
    const title = 'Prenons un café :)';
    const tags = ['rencontre', 'échange', 'partage', 'collaboration'];
    return (
      <div className={style.container}>
        <Header title={title} tags={tags} />
        <div className={style['form--container']}>
          <div className={style['form--container-fields']}>
            <form onSubmit={this.form.handleSubmit} noValidate>
              <div
                className={`${style['fields--group']} ${style['fields--group-input']}`}
              >
                <label>Nom :</label>
                <input
                  type="text"
                  name="sender_name"
                  placeholder="Entrez votre nom et prénom"
                  value={this.state.fields.sender_name}
                  onBlur={this.form.handleBlurEvent}
                  onChange={this.form.handleChangeEvent}
                  // To override the attribute name
                  data-attribute-name="Nom"
                  data-async
                />
                <p className={style.error}>
                  {this.state.errors.sender_name
                    ? this.state.errors.sender_name
                    : ''}
                </p>
              </div>
              <div
                className={`${style['fields--group']} ${style['fields--group-input']}`}
              >
                <label>Email :</label>
                <input
                  type="email"
                  name="sender_mail"
                  placeholder="Entrez votre adresse mail"
                  value={this.state.fields.sender_mail}
                  onBlur={this.form.handleBlurEvent}
                  onChange={this.form.handleChangeEvent}
                  data-attribute-name="Email"
                  data-async
                />
                <p className={style.error}>
                  {this.state.errors.sender_mail
                    ? this.state.errors.sender_mail
                    : ''}
                </p>
              </div>
              <div
                className={`${style['fields--group']} ${style['fields--group-textarea']}`}
              >
                <label>Message :</label>
                <textarea
                  type="text"
                  maxLength="800"
                  name="message"
                  placeholder="Message...(800 caractères max)"
                  value={this.state.fields.message}
                  onBlur={this.form.handleBlurEvent}
                  onChange={this.form.handleChangeEvent}
                />
                <p className={style.error}>
                  {this.state.errors.message
                    ? this.state.errors.message
                    : ''}
                </p>
              </div>
              <div>
                <input
                  type="submit"
                  className={style['input--button']}
                  value="Envoyer"
                  disabled={this.state.isMailSent}
                />
              </div>
            </form>
          </div>
          <div className={style['form--container-video']}>
            <video preload="true" muted autoPlay loop>
              <source src={video} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
