import React from 'react';
import '../styles/main.scss';
import useSticky from '../hooks/useSticky';
import NavBar from './navbar/NavBar';
import Welcome from './welcome/Welcome';
import Presentation from './presentation/Presentation';
import MyServices from './myServices/MyServices';
import Separator from './separator/Separator';
import MySkills from './mySkills/MySkills';
import MyProjects from './myProjects/MyProjects';
import Testimonials from './testimonials/Testimonials';
import MyCustomers from './myCustomers/MyCustomers';
import Contact from './contact/Contact';
import Footer from './footer/Footer';

const App = () => {
  const { isSticky, element } = useSticky();
  return (
    <>
      <NavBar sticky={isSticky} />
      <div ref={element}>
        <Welcome />
      </div>
      <div id="apropos">
        <Separator title="à propos" />
        <Presentation />
      </div>
      <div id="services">
        <Separator title="services" />
        <MyServices />
      </div>
      <div id="skills">
        <Separator title="skills" />
        <MySkills />
      </div>
      <div id="realisations">
        <Separator title="réalisations / Projets" />
        <MyProjects />
      </div>
      <div id="testimonials">
        <Separator title="témoignages" />
        <Testimonials />
      </div>
      <div id="customers">
        <Separator title="j'ai travaillé avec" />
        <MyCustomers />
      </div>
      <div id="contact">
        <Separator title="contact" />
        <Contact />
      </div>
      <Footer />
    </>
  );
};

export default App;
